import React, { useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../../components/layout"
import { StyledWrapper } from "../../components/wrapper"

const Banner = styled.div`
  background-color: ${props => props.theme.colors.liberty};
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    color: white;
    text-align: center;
  }
`

const Wrapper = styled(StyledWrapper)`
  padding: 48px 16px !important;
  font-size: 18px;

  h1,
  h2,
  h3,
  h4,
  p {
    margin-bottom: 32px;
  }

  p,
  li {
    line-height: 32px;
  }
`

const DataCategoryTable = styled.table`
  border-collapse: collapse;
  margin-bottom: 32px;

  &,
  th,
  td {
    border: 1px solid black;
  }

  th,
  td {
    padding: 8px;
  }
`

const title = "Service Level Agreement (SLA)"

export default () => {
  useEffect(() => {
    window.location.href = "https://syntage.com/legal/sla"
  }, [])

  return (
    <Layout title={title}>
      <div style={{ display: "none" }}>
        <Banner>
          <h1>{title}</h1>
        </Banner>
        <Wrapper>
          <p>Fecha de entrada en vigor: 24 de noviembre de 2020</p>
          <p>
            SATWS no garantiza que la Plataforma esté libre de errores, bugs o
            vulnerabilidades; sin embargo, brindará al Usuario, durante la
            vigencia de los{" "}
            <Link to={"/legal/terminos-y-condiciones"}>
              Términos y Condiciones
            </Link>
            , el soporte técnico que sea para que éste último pueda utilizar de
            forma regular la Plataforma, de conformidad con lo siguiente:
          </p>
          <h2>Disponibilidad (“Uptime”)</h2>
          <p>
            El Usuario podrá consultar en la siguiente liga{" "}
            <a href="https://status.sat.ws">https://status.sat.ws</a> las
            métricas de disponibilidad de la Plataforma, así como cualquier
            incidente ocurrido y la solución proporcionada por SATWS. Lo
            anterior, en el entendido de que los incidentes se solucionarán en
            el tiempo que tome a SATWS. No obstante, lo anterior, SATWS
            descontará de la factura por los Servicios mensuales las cantidades
            especificadas de conformidad con el Uptime que se indican en la
            siguiente lista:
          </p>
          <ul>
            <li>
              Menos de 99.50% de disponibilidad en el mes: 10% de descuento
            </li>
            <li>
              Menos de 99.00% de disponibilidad en el mes: 25% de descuento
            </li>
            <li>
              Menos de 95.00% de disponibilidad en el mes: 50% de descuento
            </li>
          </ul>
          <p>
            Si el Usuario hubiere pagado servicios por adelantado; entonces, el
            descuento se tomará como saldo a favor.
          </p>
          <h2>Soporte (“Mesa de Ayuda”)</h2>
          <p>
            El Usuario contará con acceso a la Mesa de Ayuda dependiendo del
            plan de soporte contratado por el Usuario (el “
            <strong>Plan de Soporte</strong>”). Los Planes de Soporte
            disponibles en la Plataforma se encuentran detallados en la sección
            correspondiente dentro de la Plataforma; incluyendo sin limitar: (i)
            tiempos de respuesta de tickets, (ii) niveles de prioridad, y (iii)
            precios. Lo anterior, en el entendido de que SATWS garantiza un
            tiempo de respuesta a los tickets generados por el Usuario de
            conformidad con el Plan de Soporte contratado; más no así el tiempo
            de resolución de incidentes.
          </p>
        </Wrapper>
      </div>
    </Layout>
  )
}
